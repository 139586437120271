body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-size: 16px;
}

.navbar-container {
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #fe0000;
  color: #fff;
  text-align: center;
  padding: 10px;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6);
}

.top-container {
  display: table;
  width: 100%;
}

.top-left-container, .top-middle-container, .top-right-container {
  display: table-cell;
  vertical-align: middle;
  font-style: italic;
}

.top-left-container > .col-md-4,
.top-middle-container > .col-md-4,
 .top-right-container > .col-md-4 {
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-logo {
  text-align: left;
}

.navbar-logo-img {
  height: 100%;
}

.family-name {
  font-size: 46px;
}

.company-name {
  line-height: 46px;
}

.company-name {
  font-size: 46px;
}

.smaller-header-text {
  font-size: 26px;
}

.license-text {
  font-size: 22px;
}

.liscense-contact-info {
  font-size: 28px;
}

.navbar-default {
  background-color: #fe0000;
}

.navbar-wrapper {
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
}

.navbar-wrapper > .navbar-text-right {
  color: #fff;
  margin-right: 15px;
  float: right;
}

.navbar-wrapper > img {
  display: inline-block;
}

.navbar-brand {
  padding: 0 !important;
  color: #FFF !important;
}

.navbar-wrapper > .navbar-text {
  color: #fff;
  margin-left: 5px;
}

.company-name-phone {
  display: inline-block;
  max-height: 100%;
}

.mobile-navbar-logo {
  padding: 0;
}

.mobile-navbar-logo-img {
  max-height: 42px;
  margin: 5px;
}

.license-text {
  font-size: 18px;
}

.phone-small {
  display: none;
}

@media (min-width: 622px) {
  .company-break, .company-name-phone > img {
    display: none;
  }
}

@media (max-width: 622px) {
  .company-name-phone {
    text-align: center;
    margin: 15px auto !important;
  }
  .company-name-phone > img {
    display: block;
    margin: 0 auto;
  }
  .navbar-wrapper > img {
    display: none;
  }
  .navbar-wrapper {
    margin: 0 auto;
    text-align: center;
  }
  .navbar-text-right {
    display: none !important;
  }
  .phone-small {
    display: block;
  }
}

.footer-container {
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  text-align: center;
  margin-top: 25px;
}

.footer-color {
  background-color: #fe0000;
}

.footer-wrapper  {
  padding: 10px;
  background-color: #fe0000;
}

.footer-header {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.footer-link, .footer-link:hover, .footer-link:visited {
  color: #fff;
  text-decoration: underline;
}

.preamble {
  text-align: center;
  margin-top: 25px;
}

.descriptor,
.about-us-header {
  font-size: 22px;
  font-weight: bold;
}

.brand-logos {
  max-width: 100%;
}

.about-us-text {
  margin-top: 40px;
  text-align: left;
}

.left-pad {
  padding-left: 10px;
}

.section-wrapper {
  margin-top: 35px;
}

.alert-wrapper {
  margin-top: 25px;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.services-container {
  margin-top: 15px;
}

.services-banner {
  font-size: 28px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}

.services-column-header {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.services-header-title {
  font-size: 20px;
}

.services-column {
  margin-top: 15px;
}

.fire-svg {
  height: 48px;
}

.carrier-report {
  font-size: 18px;
  font-weight: bold;
}

