.preamble {
  text-align: center;
  margin-top: 25px;
}

.descriptor,
.about-us-header {
  font-size: 22px;
  font-weight: bold;
}

.brand-logos {
  max-width: 100%;
}

.about-us-text {
  margin-top: 40px;
  text-align: left;
}

.left-pad {
  padding-left: 10px;
}

.section-wrapper {
  margin-top: 35px;
}

.alert-wrapper {
  margin-top: 25px;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
