.navbar-default {
  background-color: #fe0000;
}

.navbar-wrapper {
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
}

.navbar-wrapper > .navbar-text-right {
  color: #fff;
  margin-right: 15px;
  float: right;
}

.navbar-wrapper > img {
  display: inline-block;
}

.navbar-brand {
  padding: 0 !important;
  color: #FFF !important;
}

.navbar-wrapper > .navbar-text {
  color: #fff;
  margin-left: 5px;
}

.company-name-phone {
  display: inline-block;
  max-height: 100%;
}

.mobile-navbar-logo {
  padding: 0;
}

.mobile-navbar-logo-img {
  max-height: 42px;
  margin: 5px;
}

.license-text {
  font-size: 18px;
}

.phone-small {
  display: none;
}

@media (min-width: 622px) {
  .company-break, .company-name-phone > img {
    display: none;
  }
}

@media (max-width: 622px) {
  .company-name-phone {
    text-align: center;
    margin: 15px auto !important;
  }
  .company-name-phone > img {
    display: block;
    margin: 0 auto;
  }
  .navbar-wrapper > img {
    display: none;
  }
  .navbar-wrapper {
    margin: 0 auto;
    text-align: center;
  }
  .navbar-text-right {
    display: none !important;
  }
  .phone-small {
    display: block;
  }
}
