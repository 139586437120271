.navbar-container {
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #fe0000;
  color: #fff;
  text-align: center;
  padding: 10px;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6);
}

.top-container {
  display: table;
  width: 100%;
}

.top-left-container, .top-middle-container, .top-right-container {
  display: table-cell;
  vertical-align: middle;
  font-style: italic;
}

.top-left-container > .col-md-4,
.top-middle-container > .col-md-4,
 .top-right-container > .col-md-4 {
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-logo {
  text-align: left;
}

.navbar-logo-img {
  height: 100%;
}

.family-name {
  font-size: 46px;
}

.company-name {
  line-height: 46px;
}

.company-name {
  font-size: 46px;
}

.smaller-header-text {
  font-size: 26px;
}

.license-text {
  font-size: 22px;
}

.liscense-contact-info {
  font-size: 28px;
}
