.footer-container {
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  text-align: center;
  margin-top: 25px;
}

.footer-color {
  background-color: #fe0000;
}

.footer-wrapper  {
  padding: 10px;
  background-color: #fe0000;
}

.footer-header {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.footer-link, .footer-link:hover, .footer-link:visited {
  color: #fff;
  text-decoration: underline;
}
