.services-container {
  margin-top: 15px;
}

.services-banner {
  font-size: 28px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}

.services-column-header {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.services-header-title {
  font-size: 20px;
}

.services-column {
  margin-top: 15px;
}

.fire-svg {
  height: 48px;
}
